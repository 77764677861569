<template>
  <div class="home wrap">
    <SlotPageVisual>
      <template #visual-bg>
        <div class="img main"></div>
        <video muted autoplay loop playsinline>
          <source :src="`${publicPath}video/main-video.mp4`" type="video/mp4">
        </video>
      </template>
      <template #contents>
        <h1 class="visual-title ta-c">
          <span data-aos="fade" data-aos-delay="500">
            {{ $t('main.mainText.00') }}
          </span>
          <span class="hl" data-aos="fade" data-aos-delay="700">
            {{ $t('main.mainText.01') }}
          </span>
          <span data-aos="fade-down" data-aos-delay="800">
            {{ $t('main.mainText.02') }}
          </span>
          <br />
          <span class="hl" data-aos="fade-down" data-aos-delay="1000">
            {{ $t('main.mainText.03') }}
          </span>
          <span data-aos="fade-down" data-aos-delay="1500">
            {{ $t('main.mainText.04') }}
          </span>
          <span class="hl" data-aos="fade-down" data-aos-delay="1500">
            {{ $t('main.mainText.05') }}
          </span>
        </h1>
        <p class="visual-summary ta-c" data-aos="fade-up" data-aos-delay="2000">
          {{ $t('main.mainDesc') }}
        </p>
        <ScrollButton data-aos="fade-up" data-aos-delay="2500"/>
      </template>
    </SlotPageVisual>
    <OurService />
    <CustomerList class="short" />
  </div>
</template>

<script>
// @ is an alias to /src
import SlotPageVisual from '@/components/SlotPageVisual'
import OurService from '@/views/home/OurService'
import CustomerList from '@/views/home/CustomerList'
import ScrollButton from '@/components/ScrollButton'

export default {
  name: 'HomeView',
  components: {
    CustomerList,
    OurService,
    SlotPageVisual,
    ScrollButton
  },
  mounted () {
    console.log(process.env)
  },
  data () {
    return {
      publicPath: process.env.BASE_URL
    }
  }
}
</script>
