import { createRouter, createWebHashHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import HeaderNav from '@/components/HeaderNav'
import FooterList from '@/components/FooterList'

import ContactView from '@/views/ContactView'
import TermsPage from '@/views/contact/TermsPage'

import ServiceView from '@/views/ServiceView'
import ServiceDetailIM from '@/views/service/ServiceDetailIM'
import ServiceDetailSSO from '@/views/service/ServiceDetailSSO'
import ServiceDetailOTP from '@/views/service/ServiceDetailOTP'
import ServiceDetailSOF from '@/views/service/ServiceDetailSOF'
import ServiceDetailNSDAMOS from '@/views/service/ServiceDetailDAMOS'

const routes = [
  {
    path: '/',
    name: 'home',
    component:
    HomeView,
    HeaderNav,
    FooterList,
    ContactView,
    TermsPage,
    ServiceView,
    ServiceDetailIM,
    ServiceDetailSSO,
    ServiceDetailOTP,
    ServiceDetailSOF
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
  {
    path: '/service',
    name: 'service',
    component: ServiceView
  },
  {
    path: '/contact',
    name: 'contact',
    component: ContactView
  },
  {
    path: '/contact/terms',
    name: 'terms',
    component: TermsPage
  },
  {
    path: '/service/IM',
    name: 'serviceIM',
    component: ServiceDetailIM
  },
  {
    path: '/service/SSO',
    name: 'serviceSSO',
    component: ServiceDetailSSO
  },
  {
    path: '/service/OTP',
    name: 'serviceOTP',
    component: ServiceDetailOTP
  },
  {
    path: '/service/SOF',
    name: 'serviceSOF',
    component: ServiceDetailSOF
  },
  {
    path: '/service/DAMOS',
    name: 'serviceDAMOS',
    component: ServiceDetailNSDAMOS
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes,
  scrollBehavior () {
    return { top }
  }
})

export default router
