<template>
  <div class="footer-list">
    <div class="list-header">
      OFFICE.
    </div>
    <div class="list-body">
      <p>
        {{ $t('footer.name') }}
      </p>
      <p>
        {{ $t('footer.address') }}
      </p>
      <p>
        {{ $t('footer.number') }}
      </p>
    </div>
    <div class="list-header">
      CONTACT.
    </div>
    <div class="list-body">
      <p>
        {{ $t('footer.tel') }}
      </p>
      <p>
        {{ $t('footer.fax') }}
      </p>
      <p>
        {{ $t('footer.email', {account:'nandsoft', domain:'nandsoft.co.kr'}) }}
      </p>
    </div>
    <div class="list-body">
      <router-link to="/contact/terms">
        <p class="a">
          {{ $t('footer.PP') }}
        </p>
      </router-link>
      Copyright 2023. NANDSOFT Inc, All rights reserved.
    </div>
  </div>
</template>

<script>
export default {
  name: 'FooterList'
}
</script>
