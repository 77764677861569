<template>
  <div class="scroll" @click="toBottom">
    <div class="scroll-circle">
      <div class="scroll-txt">
        SCROLL
      </div>
    </div>
    <div class="scroll-bar">
      <div class="scroll-circle"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ScrollButton',
  methods: {
    toBottom: function () {
      const location = document.querySelector('#ourService').offsetTop
      window.scrollTo({ top: location, behavior: 'smooth' })
    }
  }
}
</script>

<style scoped>

</style>
