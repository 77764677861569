<template>
  <div class="terms wrap bg-light">
    <section class="section">
      <h4 class="section-title ta-l">
        {{ $t('pp.title') }}
      </h4>
      <div class="section-contents">
        <ul class="terms-list-wrap">
          <li class="terms-list">
            <h5 class="terms-list-title">
              {{ $t('pp.01.title') }}
            </h5>
            <ol class="terms-list-contents">
              <li>
                {{ $t('pp.01.contents.01') }}
              </li>
              <li>
                {{ $t('pp.01.contents.02') }}
                <div class="terms-inner-contents">
                  <b class="inner-list-title">
                    {{ $t('pp.01.contents.02-a.title') }}
                    <p>
                      {{ $t('pp.01.contents.02-a.contents') }}
                    </p>
                  </b>
                </div>
              </li>
            </ol>
          </li>
          <li class="terms-list">
            <h5 class="terms-list-title">
              {{ $t('pp.02.title') }}
            </h5>
            <ol class="terms-list-contents">
              <li>
                {{ $t('pp.02.contents.01') }}
              </li>
              <li>
                {{ $t('pp.02.contents.02') }}
              </li>
              <li>
                {{ $t('pp.02.contents.03') }}
                <div class="terms-inner-contents">
                  <b class="inner-list-title">
                    {{ $t('pp.02.contents.03-a.title') }}
                    <p>
                      {{ $t('pp.02.contents.03-a.contents.01') }}
                    </p>
                  </b>
                  <table>
                    <thead>
                    <tr>
                      <th>
                        {{ $t('pp.02.contents.03-a.contents.table.th.category') }}
                      </th>
                      <th>
                        {{ $t('pp.02.contents.03-a.contents.table.th.collect') }}
                      </th>
                      <th>
                        {{ $t('pp.02.contents.03-a.contents.table.th.use') }}
                      </th>
                      <th>
                        {{ $t('pp.02.contents.03-a.contents.table.th.period') }}
                      </th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                      <td>
                        {{ $t('pp.02.contents.03-a.contents.table.td.category.01') }}
                      </td>
                      <td>
                        {{ $t('pp.02.contents.03-a.contents.table.td.collect.01') }}
                      </td>
                      <td rowspan="2">
                        {{ $t('pp.02.contents.03-a.contents.table.td.use') }}
                      </td>
                      <td rowspan="2">
                        {{ $t('pp.02.contents.03-a.contents.table.td.period') }}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        {{ $t('pp.02.contents.03-a.contents.table.td.category.02') }}
                      </td>
                      <td>
                        {{ $t('pp.02.contents.03-a.contents.table.td.collect.02') }}
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </li>
            </ol>
          </li>
          <li class="terms-list">
            <h5 class="terms-list-title">
              {{ $t('pp.03.title') }}
            </h5>
            <ol class="terms-list-contents">
              <li>
                {{ $t('pp.03.contents.01') }}
              </li>
              <li>
                {{ $t('pp.03.contents.02') }}
              </li>
            </ol>
          </li>
          <li class="terms-list">
            <h5 class="terms-list-title">
              {{ $t('pp.04.title') }}
            </h5>
            <ol class="terms-list-contents">
              <li>
                {{ $t('pp.04.contents.01') }}
              </li>
              <li>
                {{ $t('pp.04.contents.02') }}
              </li>
            </ol>
          </li>
          <li class="terms-list">
            <h5 class="terms-list-title">
              {{ $t('pp.05.title') }}
            </h5>
            <ol class="terms-list-contents">
              <li>
                {{ $t('pp.05.contents.01') }}
                <div class="terms-inner-contents">
                  <p>
                    {{ $t('pp.05.contents.01-a.01') }}
                  </p>
                  <p>
                    {{ $t('pp.05.contents.01-a.02') }}
                  </p>
                  <p>
                    {{ $t('pp.05.contents.01-a.03') }}
                  </p>
                  <p>
                    {{ $t('pp.05.contents.01-a.04') }}
                  </p>
                </div>
              </li>
              <li>
                {{ $t('pp.05.contents.02') }}
              </li>
              <li>
                {{ $t('pp.05.contents.03') }}
              </li>
            </ol>
          </li>
          <li class="terms-list">
            <h5 class="terms-list-title">
              {{ $t('pp.06.title') }}
            </h5>
            <ol class="terms-list-contents">
              <li>
                {{ $t('pp.06.contents.01') }}
                <div class="terms-inner-contents">
                  <b class="inner-list-title">
                    {{ $t('pp.06.contents.01-a.title') }}
                    <p>
                      {{ $t('pp.06.contents.01-a.contents.01') }}
                    </p>
                    <p>
                      {{ $t('pp.06.contents.01-a.contents.02') }}
                    </p>
                  </b>
                </div>
              </li>
              <li>
                {{ $t('pp.06.contents.02') }}
                <div class="terms-inner-contents">
                  <p>
                    {{ $t('pp.06.contents.02-a') }}
                  </p>
                </div>
              </li>
            </ol>
          </li>
          <li class="terms-list">
            <h5 class="terms-list-title">
              {{ $t('pp.07.title') }}
            </h5>
            <ol class="terms-list-contents">
              <li>
                {{ $t('pp.07.contents.01') }}
              </li>
              <li>
                {{ $t('pp.07.contents.02') }}
              </li>
              <li>
                {{ $t('pp.07.contents.03') }}
                <div class="terms-inner-contents">
                  <b class="inner-list-title">
                    {{ $t('pp.07.contents.03-a.01.title') }}
                    <p>
                      {{ $t('pp.07.contents.03-a.01.contents') }}
                    </p>
                  </b>
                  <b class="inner-list-title">
                    {{ $t('pp.07.contents.03-a.02.title') }}
                    <p>
                      {{ $t('pp.07.contents.03-a.02.contents.01') }}
                    </p>
                    <p>
                      {{ $t('pp.07.contents.03-a.02.contents.02') }}
                    </p>
                  </b>
                </div>
              </li>
            </ol>
          </li>
          <li class="terms-list">
            <h5 class="terms-list-title">
              {{ $t('pp.08.title') }}
            </h5>
            <ol class="terms-list-contents">
              <li>
                {{ $t('pp.08.contents.01') }}
                <div class="terms-inner-contents">
                  <p>
                    {{ $t('pp.08.contents.01-a.01') }}
                  </p>
                  <p>
                    {{ $t('pp.08.contents.01-a.02') }}
                  </p>
                  <p>
                    {{ $t('pp.08.contents.01-a.03') }}
                  </p>
                </div>
              </li>
            </ol>
          </li>
          <li class="terms-list">
            <h5 class="terms-list-title">
              {{ $t('pp.09.title') }}
            </h5>
            <ol class="terms-list-contents">
              <li>
                {{ $t('pp.09.contents.01') }}
                <div class="terms-inner-contents">
                  <p>
                    {{ $t('pp.09.contents.01-a') }}
                  </p>
                </div>
              </li>
            </ol>
          </li>
          <li class="terms-list">
            <h5 class="terms-list-title">
              {{ $t('pp.10.title') }}
            </h5>
            <ol class="terms-list-contents">
              <li>
                {{ $t('pp.10.contents.01') }}
                <div class="terms-inner-contents">
                  <table>
                    <thead>
                    <tr>
                      <th></th>
                      <th>
                        {{ $t('pp.10.contents.01-a.table.th.ceo') }}
                      </th>
                      <th>
                        {{ $t('pp.10.contents.01-a.table.th.manager') }}
                      </th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                      <th>{{ $t('pp.10.contents.01-a.table.th.team') }}</th>
                      <td>{{ $t('pp.10.contents.01-a.table.td.team.01') }}</td>
                      <td>{{ $t('pp.10.contents.01-a.table.td.team.02') }}</td>
                    </tr>
                    <tr>
                      <th>{{ $t('pp.10.contents.01-a.table.th.name') }}</th>
                      <td>{{ $t('pp.10.contents.01-a.table.td.name.01') }}</td>
                      <td>{{ $t('pp.10.contents.01-a.table.td.name.02') }}</td>
                    </tr>
                    <tr>
                      <th>{{ $t('pp.10.contents.01-a.table.th.tel') }}</th>
                      <td>{{ $t('pp.10.contents.01-a.table.td.tel.01') }}</td>
                      <td>{{ $t('pp.10.contents.01-a.table.td.tel.02') }}</td>
                    </tr>
                    <tr>
                      <th>{{ $t('pp.10.contents.01-a.table.th.email') }}</th>
                      <td>{{ $t('pp.10.contents.01-a.table.td.email', {account:'yskim', domain:'nandsoft.co.kr'}) }}</td>
                      <td>{{ $t('pp.10.contents.01-a.table.td.email', {account:'gusrnr39', domain:'nandsoft.co.kr'}) }}</td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </li>
              <li>
                {{ $t('pp.10.contents.02') }}
              </li>
            </ol>
          </li>
          <li class="terms-list">
            <h5 class="terms-list-title">
              {{ $t('pp.11.title') }}
            </h5>
            <ol class="terms-list-contents">
              <li>
                {{ $t('pp.11.contents.01') }}
              </li>
            </ol>
          </li>
          <li class="terms-list">
            <h5 class="terms-list-title">
              {{ $t('pp.12.title') }}
            </h5>
            <ol class="terms-list-contents">
              <li>
                {{ $t('pp.12.contents.01') }}
                <div class="terms-inner-contents">
                  <b class="inner-list-title">
                    {{ $t('pp.12.contents.01-a.title') }}
                    <p>
                      {{ $t('pp.12.contents.01-a.contents.01') }}
                    </p>
                    <p>
                      {{ $t('pp.12.contents.01-a.contents.02') }}
                    </p>
                    <p>
                      {{ $t('pp.12.contents.01-a.contents.03') }}
                    </p>
                  </b>
                  <b class="inner-list-title">
                    {{ $t('pp.12.contents.01-b.title') }}
                    <p>
                      {{ $t('pp.12.contents.01-a.contents.01') }}
                    </p>
                    <p>
                      {{ $t('pp.12.contents.01-a.contents.02') }}
                    </p>
                    <p>
                      {{ $t('pp.12.contents.01-a.contents.03') }}
                    </p>
                  </b>
                  <b class="inner-list-title">
                    {{ $t('pp.12.contents.01-c.title') }}
                    <p>
                      {{ $t('pp.12.contents.01-c.contents.01') }}
                    </p>
                    <p>
                      {{ $t('pp.12.contents.01-c.contents.02') }}
                    </p>
                  </b>
                  <b class="inner-list-title">
                    {{ $t('pp.12.contents.01-d.title') }}
                    <p>
                      {{ $t('pp.12.contents.01-d.contents.01') }}
                    </p>
                    <p>
                      {{ $t('pp.12.contents.01-d.contents.02') }}
                    </p>
                  </b>
                </div>
              </li>
            </ol>
          </li>
          <li class="terms-list">
            <h5 class="terms-list-title">
              {{ $t('pp.13.title') }}
            </h5>
            <ol class="terms-list-contents">
              <li>
                {{ $t('pp.13.contents.01') }}
              </li>
              <li>
                {{ $t('pp.13.contents.02') }}
                <div class="terms-inner-contents">
                  <b class="inner-list-title">
                    {{ $t('pp.13.contents.02-a.title') }}
                    <p>
                      {{ $t('pp.13.contents.02-a.contents') }}
                    </p>
                  </b>
                  <b class="inner-list-title">
                    {{ $t('pp.13.contents.02-b.title') }}
                    <p>
                      {{ $t('pp.13.contents.02-b.contents') }}
                    </p>
                  </b>
                  <b class="inner-list-title">
                    {{ $t('pp.13.contents.02-c.title') }}
                    <p>
                      {{ $t('pp.13.contents.02-c.contents') }}
                    </p>
                  </b>
                </div>
              </li>
            </ol>
          </li>
        </ul>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: 'termsPage'
}
</script>

<style scoped>
</style>
