<template>
  <div class="service detail wrap">
    <div class="text-bg otp">
      <div class="text-wrap">
        <p>
          {{ $t('service.detail.sso.lineText.01') }}
        </p>
        <p>
          {{ $t('service.detail.sso.lineText.02') }}
        </p>
        <p>
          {{ $t('service.detail.sso.lineText.03') }}
        </p>
      </div>
    </div>
    <HalfPageLayout class="otp title">
      <template #half-left>
        <div class="img otp"></div>
      </template>
      <template #half-right>
        <div class="inner-contents">
          <div class="contents-title">
            <h3 style="line-height: 1.1">
              {{ $t('service.title.otp') }}
            </h3>
            <h6>One Time Password</h6>
            <h4>
              {{ $t('service.detail.otp.subTitle') }}
            </h4>
          </div>
          <h5>
            {{ $t('service.detail.otp.desc') }}
          </h5>
        </div>
      </template>
    </HalfPageLayout>
    <HalfPageLayout class="otp summary">
      <template #half-left>
        <div class="animation-bg">
          <div class="gear">
            <SvgGear />
          </div>
          <div class="gear">
            <SvgGear />
          </div>
        </div>
        <div class="inner-contents">
          <div class="section-title"  data-aos="fade" >
            <h3 class="hl">
              {{ $t('service.detail.otp.firstPage.title') }}
            </h3>
          </div>
          <div class="section-contents">
            <div class="contents-list icon" data-aos="fade" data-aos-delay="500">
              <Icon icon="ph:lock-open-thin"></Icon>
              <dl>
                <dt>
                  {{ $t('service.detail.otp.firstPage.contents.01.title') }}
                </dt>
                <dd>
                  {{ $t('service.detail.otp.firstPage.contents.01.desc') }}
                </dd>
              </dl>
            </div>
            <div class="contents-list icon" data-aos="fade" data-aos-delay="1000">
              <Icon icon="ph:apple-podcasts-logo-thin"></Icon>
              <dl>
                <dt>
                  {{ $t('service.detail.otp.firstPage.contents.02.title') }}
                </dt>
                <dd>
                  {{ $t('service.detail.otp.firstPage.contents.02.desc') }}
                </dd>
              </dl>
            </div>
            <div class="contents-list icon" data-aos="fade" data-aos-delay="1500">
              <Icon icon="ph:circles-three-plus-thin"></Icon>
              <dl>
                <dt>
                  {{ $t('service.detail.otp.firstPage.contents.03.title') }}
                </dt>
                <dd>
                  {{ $t('service.detail.otp.firstPage.contents.03.desc') }}
                </dd>
              </dl>
            </div>
          </div>
        </div>
      </template>
      <template #half-right>
        <div class="img visual-01"></div>
      </template>
    </HalfPageLayout>
    <section class="section otp benefit">
      <h2 class="hl section-title">
        {{ $t('service.detail.otp.category.title') }}
      </h2>
      <div class="section-contents">
        <div class="inner-contents">
          <div class="contents-list">
            <dl data-aos="zoom-in">
              <div class="img otp"></div>
              <dt>
                {{ $t('service.detail.otp.category.contents.01.title') }}
              </dt>
              <dd>
                {{ $t('service.detail.otp.category.contents.01.desc') }}
              </dd>
            </dl>
            <dl data-aos="zoom-in">
              <div class="img otp"></div>
              <dt>
                {{ $t('service.detail.otp.category.contents.02.title') }}
              </dt>
              <dd>
                {{ $t('service.detail.otp.category.contents.02.desc') }}
              </dd>
            </dl>
            <dl data-aos="zoom-in">
              <div class="img otp"></div>
              <dt>
                {{ $t('service.detail.otp.category.contents.03.title') }}
              </dt>
              <dd>
                {{ $t('service.detail.otp.category.contents.03.desc') }}
              </dd>
            </dl>
            <dl data-aos="zoom-in">
              <div class="img otp"></div>
              <dt>
                {{ $t('service.detail.otp.category.contents.04.title') }}
              </dt>
              <dd>
                {{ $t('service.detail.otp.category.contents.04.desc') }}
              </dd>
            </dl>
            <dl data-aos="zoom-in">
              <div class="img otp"></div>
              <dt>
                {{ $t('service.detail.otp.category.contents.05.title') }}
              </dt>
              <dd>
                {{ $t('service.detail.otp.category.contents.05.desc') }}
              </dd>
            </dl>
          </div>
        </div>
        <router-link to="/contact" class="button big line">
          {{ $t('service.detail.contact') }}
        </router-link>
      </div>
    </section>
  </div>
</template>

<script>
import HalfPageLayout from '@/components/HalfPageLayout'
import { Icon } from '@iconify/vue'
import SvgGear from '@/components/SvgGear'

export default {
  name: 'serviceDetailIm',
  components: { SvgGear, HalfPageLayout, Icon },
  data () {
    return {
    }
  }
}
</script>

<style scoped>

</style>
