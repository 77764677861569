<template>
  <div class="section-title" data-aos="fade-up">
    <h1>{{title}}</h1>
    <h5>{{summary}}</h5>
  </div>
</template>

<script>
export default {
  name: 'SectionTitle',
  props: {
    title: {
      type: String
    },
    summary: {
      type: String
    }
  }
}
</script>

<style scoped>

</style>
