<template>
  <div class="contents-title">
    <h3>{{title}}</h3>
    <h5>{{summary}}</h5>
  </div>
</template>

<script>
export default {
  name: 'ContentsTitle',
  props: {
    title: {
      type: String
    },
    summary: {
      type: String
    }
  }
}
</script>

<style scoped>

</style>
