<template>
  <section class="half-page-wrap">
    <div class="half-page left">
      <slot name="half-left"></slot>
    </div>
    <div class="half-page right">
      <slot name="half-right"></slot>
    </div>
  </section>
</template>

<script>

export default {
  name: 'HalfPageLayout'
}
</script>

<style scoped>

</style>
