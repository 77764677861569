<template>
  <div class="service detail wrap">
    <div class="text-bg sof">
      <div class="text-wrap">
        <p>
          {{ $t('service.detail.sof.lineText.01') }}
        </p>
        <p>
          {{ $t('service.detail.sof.lineText.02') }}
        </p>
        <p>
          {{ $t('service.detail.sof.lineText.03') }}
        </p>
      </div>
    </div>
    <HalfPageLayout class="sof title">
      <template #half-left>
        <div class="img sof"></div>
      </template>
      <template #half-right>
        <div class="inner-contents">
          <div class="contents-title">
            <h3 style="line-height: 1.1">
              {{ $t('service.title.sof') }}
            </h3>
            <h6>Smart Office</h6>
            <h4>
              {{ $t('service.detail.sof.subTitle') }}
            </h4>
          </div>
          <h5>
            {{ $t('service.detail.sof.desc') }}
          </h5>
        </div>
      </template>
    </HalfPageLayout>
    <HalfPageLayout class="sof summary bg-light">
      <template #half-left>
        <div class="inner-contents">
          <div class="section-title"  data-aos="fade" >
            <h3 class="hl">
              {{ $t('service.detail.sof.firstPage.title') }}
            </h3>
          </div>
          <div class="section-contents">
            <div class="contents-list icon" data-aos="fade" data-aos-delay="500">
              <Icon icon="ph:buildings-thin"></Icon>
              <dl>
                <dt>
                  {{ $t('service.detail.sof.firstPage.contents.01.title') }}
                </dt>
                <dd>
                  {{ $t('service.detail.sof.firstPage.contents.01.desc') }}
                </dd>
              </dl>
            </div>
            <div class="contents-list icon" data-aos="fade" data-aos-delay="1000">
              <Icon icon="ph:desktop-tower-thin"></Icon>
              <dl>
                <dt>
                  {{ $t('service.detail.sof.firstPage.contents.02.title') }}
                </dt>
                <dd>
                  {{ $t('service.detail.sof.firstPage.contents.02.desc') }}
                </dd>
              </dl>
            </div>
            <div class="contents-list icon" data-aos="fade" data-aos-delay="1500">
              <Icon icon="ph:projector-screen-chart-thin"></Icon>
              <dl>
                <dt>
                  {{ $t('service.detail.sof.firstPage.contents.03.title') }}
                </dt>
                <dd>
                  {{ $t('service.detail.sof.firstPage.contents.03.desc') }}
                </dd>
              </dl>
            </div>
          </div>
        </div>
      </template>
      <template #half-right>
        <div class="img visual-01"></div>
      </template>
    </HalfPageLayout>
    <HalfPageLayout class="sof summary bg-light">
      <template #half-left>
        <div class="img visual-02"></div>
      </template>
      <template #half-right>
        <div class="animation-bg">
          <div class="gear">
            <SvgGear />
          </div>
          <div class="gear">
            <SvgGear />
          </div>
        </div>
        <div class="inner-contents">
          <div class="section-title"  data-aos="fade" >
            <h3 class="hl">
              {{ $t('service.detail.sof.secondPage.title') }}
            </h3>
          </div>
          <div class="section-contents">
            <div class="contents-list icon" data-aos="fade" data-aos-delay="500">
              <Icon icon="ph:calendar-check-thin"></Icon>
              <dl>
                <dt>
                  {{ $t('service.detail.sof.secondPage.contents.01.title') }}
                </dt>
                <dd>
                  {{ $t('service.detail.sof.secondPage.contents.01.desc') }}
                </dd>
              </dl>
            </div>
            <div class="contents-list icon" data-aos="fade" data-aos-delay="1000">
              <Icon icon="ph:office-chair-thin"></Icon>
              <dl>
                <dt>
                  {{ $t('service.detail.sof.secondPage.contents.02.title') }}
                </dt>
                <dd>
                  {{ $t('service.detail.sof.secondPage.contents.02.desc') }}
                </dd>
              </dl>
            </div>
            <div class="contents-list icon" data-aos="fade" data-aos-delay="1500">
              <Icon icon="ph:clock-countdown-thin"></Icon>
              <dl>
                <dt>
                  {{ $t('service.detail.sof.secondPage.contents.03.title') }}
                </dt>
                <dd>
                  {{ $t('service.detail.sof.secondPage.contents.03.desc') }}
                </dd>
              </dl>
            </div>
          </div>
        </div>
      </template>
    </HalfPageLayout>
    <section class="section sof benefit">
      <h2 class="hl section-title">
        {{ $t('service.detail.sof.benefit.title') }}
      </h2>
      <div class="section-contents">
        <div class="contents-list">
          <dl data-aos="zoom-in">
            <div class="img sof"></div>
            <dt>
              {{ $t('service.detail.sof.benefit.contents.01.title') }}
            </dt>
            <dd>
              {{ $t('service.detail.sof.benefit.contents.01.desc') }}
            </dd>
          </dl>
          <dl data-aos="zoom-in">
            <div class="img sof"></div>
            <dt>
              {{ $t('service.detail.sof.benefit.contents.02.title') }}
            </dt>
            <dd>
              {{ $t('service.detail.sof.benefit.contents.02.desc') }}
            </dd>
          </dl>
          <dl data-aos="zoom-in">
            <div class="img sof"></div>
            <dt>
              {{ $t('service.detail.sof.benefit.contents.03.title') }}
            </dt>
            <dd>
              {{ $t('service.detail.sof.benefit.contents.03.desc') }}
            </dd>
          </dl>
        </div>
        <router-link to="/contact" class="button big line">
          문의하기
        </router-link>
      </div>
    </section>
  </div>
</template>

<script>
import HalfPageLayout from '@/components/HalfPageLayout'
import { Icon } from '@iconify/vue'
import SvgGear from '@/components/SvgGear'

export default {
  name: 'serviceDetailIm',
  components: { SvgGear, HalfPageLayout, Icon },
  data () {
    return {
    }
  }
}
</script>

<style scoped>

</style>
