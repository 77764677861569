<template>
  <div class="contact wrap">
    <SlotPageVisual>
      <template #visual-bg>
        <div class="img contact"></div>
      </template>
      <template #contents>
        <HalfPageLayout>
          <template #half-left>
            <div class="inner-contents">
              <h1 class="visual-title">
                {{ $t('menu.contact') }}
              </h1>
              <p class="visual-summary">
                {{ $t('contact.desc') }}
              </p>
              <div class="form-wrap mt-20">
                <div class="field">
                  <div class="mb-20">
                    <p>
                      {{ $t('contact.notice.01') }}
                    </p>
                    <p>
                      {{ $t('contact.notice.02') }}
                    </p>
                  </div>
                  <div class="box">
                    <ul>
                      <li class="list">
                        <div class="title">
                          {{ $t('contact.privacyPolicy.summary.01.title') }}
                        </div>
                        {{ $t('contact.privacyPolicy.summary.01.desc') }}
                      </li>
                      <li class="list">
                        <div class="title">
                          {{ $t('contact.privacyPolicy.summary.02.title') }}
                        </div>
                        {{ $t('contact.privacyPolicy.summary.02.desc') }}
                      </li>
                      <li class="list">
                        <div class="title">
                          {{ $t('contact.privacyPolicy.summary.03.title') }}
                        </div>
                        {{ $t('contact.privacyPolicy.summary.03.desc') }}
                      </li>
                      <li class="list">
                        <div class="title">
                          <router-link to="/contact/terms" target="_blank">
                            {{ $t('contact.privacyPolicy.fullText') }}
                          </router-link>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </template>
          <template #half-right>
            <div class="inner-contents">
              <div class="form-wrap">
                <form action="/mailSender/homePageContact" id="sendQuest" method="POST">
                  <div class="field">
                    <label for="name">
                      {{ $t('contact.form.name') }}
                    </label>
                    <input type="text" id="name" :placeholder="$t('contact.form.name')" v-model="contact.ctName" :class="{redAlert: !checkName}">
                  </div>
                  <div class="field">
                    <label for="corp">
                      {{ $t('contact.form.corp') }}
                    </label>
                    <input type="text" id="corp" :placeholder="$t('contact.form.corp')" v-model="contact.ctCorp"/>
                  </div>
                  <div class="field">
                    <label for="tel">
                      {{ $t('contact.form.tel') }}
                    </label>
                    <input type="tel" id="tel" :placeholder="$t('contact.form.tel')" v-model="contact.ctPhone" :class="{redAlert: !checkPhone}"/>
                  </div>
                  <div class="field">
                    <label for="email">
                      {{ $t('contact.form.email') }}
                    </label>
                    <input type="email" id="email" :placeholder="$t('contact.form.email')" v-model="contact.ctEmail" :class="{redAlert: !checkEmail}"/>
                  </div>
                  <div class="field">
                    <label for="text">
                      {{ $t('contact.form.text') }}
                    </label>
                    <textarea type="text" id="text" :placeholder="$t('contact.form.text')" v-model="contact.ctText" :class="{redAlert: !checkText}"/>
                  </div>
                  <div class="field">
                    <input type="checkbox" id="ctCheck" v-model="agreePrivacy">
                    <label for="ctCheck">
                      {{ $t('contact.form.agree') }}
                    </label>
                  </div>
                  <div class="field ta-c">
                    <button type="button" class="big line button" v-on:click="sendContactMail">
                      {{ $t('contact.form.send') }}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </template>
        </HalfPageLayout>
      </template>
    </SlotPageVisual>
    <HalfPageLayout >
      <template #half-left>
        <div class="map-wrap">
          <KakaoMap />
        </div>
      </template>
      <template #half-right>
        <div class="inner-contents contact-info">
          <div class="section-title">
            <h1 class="line-text">
              NANDSOFT<br /> Inc.
            </h1>
          </div>
          <div class="section-contents">
            <dl class="contact-info">
              <dt>
                {{ $t('contact.info.tel.title') }}
              </dt>
              <dd>
                {{ $t('contact.info.tel.text') }}
              </dd>
            </dl>
            <dl class="contact-info">
              <dt>
                {{ $t('contact.info.fax.title') }}
              </dt>
              <dd>
                {{ $t('contact.info.fax.text') }}
              </dd>
            </dl>
            <dl class="contact-info">
              <dt>
                {{ $t('contact.info.address.title') }}
              </dt>
              <dd>
                {{ $t('contact.info.address.text') }}
              </dd>
            </dl>
            <dl class="contact-info">
              <dt>
                {{ $t('contact.info.email.title') }}
              </dt>
              <dd>
                {{ $t('contact.info.email.text', {account:'nandsoft', domain:'nandsoft.co.kr'}) }}
              </dd>
            </dl>
          </div>
        </div>
      </template>
    </HalfPageLayout>
  </div>
</template>

<script>
import SlotPageVisual from '@/components/SlotPageVisual'
import HalfPageLayout from '@/components/HalfPageLayout'
import KakaoMap from '@/components/KakaoMap'
import axios from 'axios'

export default {
  name: 'ContactView',
  components: {
    KakaoMap,
    HalfPageLayout,
    SlotPageVisual
  },
  props: {
    msg: String
  },
  data () {
    return {
      contact: {
        ctName: null,
        ctCorp: null,
        ctPhone: null,
        ctEmail: null,
        ctText: null
      },
      agreePrivacy: false,
      defaultText: null
    }
  },
  computed: {
    checkName: function () {
      return !this.agreePrivacy || ((this.contact.ctName ?? '').trim().length > 0)
    },
    checkPhone: function () {
      const regExp = /^[0-9-+]+$/
      return !this.agreePrivacy || (regExp.test((this.contact.ctPhone ?? '').trim()))
    },
    checkEmail: function () {
      const regExp = /[0-9a-zA-Z][_0-9a-zA-Z-]*@[_0-9a-zA-Z-]+(\.[_0-9a-zA-Z-]+){1,2}$/
      return !this.agreePrivacy || ((this.contact.ctEmail ?? '').trim().match(regExp))
    },
    checkText: function () {
      return !this.agreePrivacy || ((this.contact.ctText ?? '').trim().length > 0 && this.contact.ctText !== this.defaultText)
    }
  },
  methods: {
    sendContactMail: function () {
      if (this.agreePrivacy && this.checkName && this.checkPhone && this.checkEmail && this.checkText) {
        // console.log(this.contact)
        axios.post('/api/homePageContact', this.contact)
          .then(res => {
            console.log(res)
            if (res && res.status === 200) {
              if (res.data.ResultStatus === 'SUCCESS') {
                alert(res.data.ResultMessage)
                // this.reset()
              } else {
                alert(res.data.ResultMessage)
              }
            } else {
              alert('오류가 발생하였습니다.')
            }
          })
          .catch(function (error) {
            console.log(error)
            alert('오류가 발생하였습니다.')
          })
      } else if (!this.agreePrivacy) {
        alert('수집 및 이용 안내에 동의해주세요.')
      } else {
        alert('내용을 정확하게 입력해 주시기 바랍니다.')
      }
    }
    // reset () {
    //   Object.assign(this.$data, this.initialStatue())
    // }
  }
}
</script>

<style>
</style>
