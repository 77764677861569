import { throttle } from 'lodash'

export const scrollDownEvent = {
  data () {
    return {
      isScrollDown: false
    }
  },
  mounted () {
    this.handleScroll = throttle(this.onScroll, 500)
    document.addEventListener('scroll', this.handleScroll)
  },
  beforeUnmount () {
    document.removeEventListener('scroll', this.handleScroll)
  },
  computed: {
    resizeHeight () {
      return this.windowHeight
    }
  },
  methods: {
    onScroll: function () {
      this.scrollTop = document.documentElement.scrollTop
      this.isScrollDown = this.scrollTop > 500
    }
  }
}
