<template>
  <div id="map"></div>
</template>
<style>
#map {
  position: absolute;
  width: 100%;
  height: 100%;
}
</style>
<script>
export default {
  name: 'KakaoMap',
  data () {
    return {
      map: null
    }
  },
  mounted () {
    window.scrollTo(0, 0)
    document.title = 'NAMDSOFT | About'
    window.kakao && window.kakao.maps ? this.initMap() : this.addScript()
    setTimeout(function () {
      this.isActive = true
    }.bind(this), 100)
  },
  methods: {
    initMap () {
      const container = document.getElementById('map')
      const options = {
        center: new kakao.maps.LatLng(35.873121, 128.749589),
        level: 3
      }

      const map = new kakao.maps.Map(container, options)
      const imageSrc = 'images/marker.png' // 마커이미지의 주소입니다
      const imageSize = new kakao.maps.Size(34, 52) // 마커이미지의 크기입니다
      const imageOption = { offset: new kakao.maps.Point(20, 50) } // 마커이미지의 옵션입니다. 마커의 좌표와 일치시킬 이미지 안에서의 좌표를 설정합니다.
      // 마커의 이미지정보를 가지고 있는 마커이미지를 생성합니다
      const markerImage = new kakao.maps.MarkerImage(imageSrc, imageSize, imageOption)
      const markerPosition = new kakao.maps.LatLng(35.873121, 128.749589) // 마커가 표시될 위치입니다
      // 마커를 생성합니다
      const marker = new kakao.maps.Marker({
        position: markerPosition,
        image: markerImage // 마커이미지 설정
      })
      // 마커가 지도 위에 표시되도록 설정합니다
      marker.setMap(map)
    },
    addScript () {
      const script = document.createElement('script') /* global kakao */
      script.src = 'https://dapi.kakao.com/v2/maps/sdk.js?appkey=d44b981172300d1e17b982cbcd72bf0e&autoload=false'
      script.addEventListener('load', () => {
        kakao.maps.load(this.initMap)
      })
      document.head.appendChild(script)
    }
  }
}
</script>

<style scoped>

</style>
