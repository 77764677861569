<template>
  <div class="top button line" @click="toTop" v-show="isScrollDown">
    <Icon icon="ph:arrow-up-thin"></Icon>
  </div>
</template>

<script>
import { Icon } from '@iconify/vue'
import { scrollDownEvent } from '@/mixin/scrollDownEvent'

export default {
  mixins: [scrollDownEvent],
  name: 'topButton',
  components: { Icon },
  data () {
    return {
      scrollTop: 0
    }
  },
  methods: {
    toTop: function () {
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      })
    }
  }
}
</script>

<style scoped>

</style>
