<template>
  <section class="section visual">
    <div class="visual-bg">
      <slot name="visual-bg"></slot>
      <div class="darken"></div>
    </div>
    <div class="visual-contents">
      <slot name="contents">
      </slot>
    </div>
  </section>
</template>

<script>
export default {
  name: 'PageVisual'
}
</script>
