<template>
  <nav class="navbar" :class="{ 'active': isScrollDown || lightBgNav }" ref="nav">
    <HeaderNav />
  </nav>
  <main id="main">
    <router-view :key="$route.fullPath"/>
  </main>
  <footer class="footer">
    <FooterList />
  </footer>
  <Transition name="fade">
    <TopButton />
  </Transition>
</template>
<script>
import HeaderNav from '@/components/HeaderNav'
import FooterList from '@/components/FooterList'
import TopButton from '@/components/TopButton'
import AOS from 'aos'
import { scrollDownEvent } from '@/mixin/scrollDownEvent'

export default {
  mixins: [scrollDownEvent],
  name: 'HomeView',
  components: {
    TopButton,
    HeaderNav,
    FooterList,
    // eslint-disable-next-line vue/no-unused-components
    AOS
  },
  data () {
    return {
      lightBgNav: false
    }
  },
  watch: {
    '$route' (to, from) {
      const path = to.path
      if (path === '/contact/terms') {
        this.lightBgNav = true
      } else {
        this.lightBgNav = false
      }
    }
  }
}

</script>
<style lang="scss">
@import "assets/css/reset.css";
@import "assets/css/common.scss";
</style>
